<template>
  <b-modal
    id="modal__event"
    v-if="event != null"
    modal-class="main_menu__modal"
    dialog-class="main_menu__dialog"
    content-class="main_menu__content"
    body-class="main_menu__body"
    centered
    hide-footer
    hide-header
    scrollable
    size="lg"
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-container>
      <b-row align-h="center">
        <div class="event-img__container">
          <button
            :disabled="loading"
            class="exit_button"
            @click="closeModal()"
            v-if="type == 'edit'"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                fill="red"
              />
            </svg>
          </button>

          <button
            :disabled="loading"
            class="exit_button"
            @click="closeAddModal()"
            v-if="type == 'add'"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41L17.59 5Z"
                fill="red"
              />
            </svg>
          </button>

          <img
            v-if="event.img != null"
            class="event-img__img"
            :src="$store.state.storageUrl + '/app/events/' + event.img"
            alt=""
          />

          <img
            v-else
            class="event-img__img"
            :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
            alt=""
          />
        </div>
      </b-row>

      <b-row align-h="center" class="admin_event_editor__wrapper">
        <b-col cols="12" md="8">
          <b-form>
            <b-form-group label="Картинка*">
              <b-form-file v-model="event.new_img"></b-form-file>
            </b-form-group>

            <b-form-group label="Название*">
              <b-form-input v-model="event.title"></b-form-input>
            </b-form-group>

            <b-form-group label="Описание*">
              <b-form-textarea
                rows="3"
                max-rows="10"
                v-model="event.description"
              ></b-form-textarea>
            </b-form-group>

            <b-form-group label="Количество мест* (Только число)">
              <b-form-input v-model="event.count_seats"></b-form-input>
            </b-form-group>

            <b-form-group label="Спикер*">
              <b-form-input v-model="event.speaker"></b-form-input>
            </b-form-group>

            <b-form-group label="О спикере*">
              <b-form-input v-model="event.about_speaker"></b-form-input>
            </b-form-group>

            <b-form-group label="Место проведения*">
              <b-form-input v-model="event.location"></b-form-input>
            </b-form-group>

            <b-form-group label="Дата*">
              <b-form-datepicker
                locale="ru-RU"
                v-model="event.date"
                calendar-width="100%"
                menu-class="w-100"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group label="Время*">
              <b-form-timepicker
                hide-header
                no-close-button
                locale="ru-RU"
                v-model="event.time"
                calendar-width="100%"
                menu-class="w-100"
              ></b-form-timepicker>
            </b-form-group>

            <p class="block_title text-center">Цены</p>
            <div
              v-for="(price, i) in event.prices"
              :key="'price' + i"
              class="event_admin__price"
            >
              <b-row
                class="modal__heder_wrapper price_header"
                align-h="end"
                align-v="start"
              >
                <button
                  type="button"
                  @click="remove(i, 'default')"
                  :disabled="loading"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="close__button"
                      d="M19 5L5 19M5.00001 5L19 19"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group label="Количество мест">
                    <b-form-input v-model="price.data.seats"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group label="Цена">
                    <b-form-input v-model="price.data.value"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-button
              :disabled="loading"
              block
              variant="outline-secondary"
              @click="addPrice('default')"
              class="add_price_event"
              >Добавить цену</b-button
            >

            <p class="block_title text-center">Для участников БК</p>
            <div
              v-for="(bk_price, i) in event.bk_prices"
              :key="'bk_price' + i"
              class="event_admin__price"
            >
              <b-row
                class="modal__heder_wrapper price_header"
                align-h="end"
                align-v="start"
              >
                <button
                  type="button"
                  @click="remove(i, 'bk')"
                  :disabled="loading"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      class="close__button"
                      d="M19 5L5 19M5.00001 5L19 19"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </b-row>

              <b-row>
                <b-col cols="6">
                  <b-form-group label="Количество мест">
                    <b-form-input v-model="bk_price.data.seats"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="6">
                  <b-form-group label="Цена">
                    <b-form-input v-model="bk_price.data.value"></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
            <b-button
              :disabled="loading"
              block
              variant="outline-secondary"
              @click="addPrice('bk')"
              class="add_price_event"
              >Добавить цену</b-button
            >

            <b-button
              :disabled="loading"
              class="main__button"
              type="submit"
              v-if="type == 'edit'"
              @click="saveEvent"
              >Сохранить</b-button
            >
            <b-button
              :disabled="loading"
              class="main__button"
              type="submit"
              v-else
              @click="createEvent"
              >Создать мероприятие</b-button
            >
          </b-form>
        </b-col>
      </b-row>

      <b-row align-h="center" align-v="center" v-if="type == 'edit'">
        <b-col>
          <b-button
            :disabled="loading"
            type="button"
            @click="deleteEvent()"
            block
            variant="outline-danger"
            class="add_price_event"
            >Удалить мероприятие</b-button
          >
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import axios from "axios";

export default {
  name: "EventModal",
  computed: {
    event() {
      return this.$store.state.events.editingEvent;
    },
    type() {
      return this.$store.state.events.typeEditing;
    },
    loading() {
      return this.$store.state.loading;
    },
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("modal__event");
    },
    closeAddModal() {
      this.$store.state.events.events.splice(
        this.$store.state.events.events.length - 1,
        1
      );
      this.$store.commit("SET_TYPE_EDITING", "edit");
      this.$bvModal.hide("modal__event");
    },
    // Prices editing
    addPrice(type) {
      switch (type) {
        case "default":
          this.event.prices.push({
            data: {
              value: "",
              seats: "",
            },
          });
          break;

        case "bk":
          this.event.bk_prices.push({
            data: {
              value: "",
              seats: "",
            },
          });
          break;
      }
    },
    remove(i, type) {
      switch (type) {
        case "default":
          this.event.prices.splice(i, 1);
          break;

        case "bk":
          this.event.bk_prices.splice(i, 1);
          break;
      }
    },
    // Events
    async deleteEvent() {
      if (confirm('Удалить мероприятие "' + this.event.title + '" ?')) {
        try {
          this.$store.commit("SET_LOADING", true);

          let events = await axios
            .delete("events/" + this.event.id)
            .then((res) => {
              return res.data;
            });

          this.$bvToast.toast(
            'Мероприятие "' + this.event.title + '" Успешно удалено'
          );
          this.$store.commit("SET_EVENTS", events);
          this.closeModal();

          this.$store.commit("SET_LOADING", false);
        } catch (error) {
          this.$store.commit("SET_LOADING", false);
          console.error(error);
        }
      }
    },
    async saveEvent(e) {
      e.preventDefault();

      let formData = new FormData();

      formData.append("title", this.event.title);
      formData.append("description", this.event.description);
      formData.append("speaker", this.event.speaker);
      formData.append("about_speaker", this.event.about_speaker);
      formData.append("location", this.event.location);
      formData.append("date", this.event.date);
      formData.append("time", this.event.time);
      formData.append("count_seats", this.event.count_seats);
      formData.set("bk_prices", JSON.stringify(this.event.bk_prices));
      formData.set("prices", JSON.stringify(this.event.prices));
      formData.append("new_img", this.event.new_img);

      try {
        this.$store.commit("SET_LOADING", true);
        let events = await axios
          .post("edit_event/" + this.event.id, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            return res.data;
          });

        this.$store.commit("SET_EVENTS", events);

        this.$bvToast.toast("Сохранено");
        this.closeModal();

        this.$store.commit("SET_LOADING", false);
      } catch (error) {
        this.$store.commit("SET_LOADING", false);
        console.error(error);
      }
    },
    async createEvent(e) {
      e.preventDefault();

      let formData = new FormData();

      formData.append("title", this.event.title);
      formData.append("description", this.event.description);
      formData.append("speaker", this.event.speaker);
      formData.append("about_speaker", this.event.about_speaker);
      formData.append("location", this.event.location);
      formData.append("date", this.event.date);
      formData.append("time", this.event.time);
      formData.append("count_seats", this.event.count_seats);
      formData.set("bk_prices", JSON.stringify(this.event.bk_prices));
      formData.set("prices", JSON.stringify(this.event.prices));
      formData.append("new_img", this.event.new_img);

      try {
        this.$store.commit("SET_LOADING", true);

        let events = await axios
          .post("events", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            return res.data;
          });

        this.$store.commit("SET_EVENTS", events);

        this.$bvToast.toast("Мероприятие создано");
        this.closeModal();

        this.$store.commit("SET_LOADING", false);
      } catch (error) {
        this.$store.commit("SET_LOADING", false);
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
.event_admin__price {
  border: 1px solid $gray__light;
  padding: 15px;
  margin-bottom: 30px;
}
.checkbox_bk {
  padding-bottom: 15px;
}
.admin_event_editor__wrapper {
  padding-bottom: 60px;
}
.add_price_event {
  margin-bottom: 60px;
  border-radius: 0;
}
.modal__heder_wrapper.price_header {
  height: auto;
  padding: 0 15px 15px 0;
}
</style>
