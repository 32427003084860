<template>
  <div class="events">
    <div class="padding_top"></div>

    <b-container>
      <b-row class="page_header" align-h="center">
        <b-col cols="auto">
          <h1 class="page_title">
            <span class="accent_color">BK</span> Мероприятия
          </h1>
          <p class="page_subtitle">Редактор</p>
        </b-col>
      </b-row>

      <b-row v-if="events">
        <b-col cols="12" md="4">
          <div class="event">
            <div class="event-img__container">
              <img
                class="event-img__img"
                :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
                alt=""
              />
            </div>

            <div class="title-event_wrapper">
              <h3 class="title-event">Новое мероприятие</h3>
            </div>

            <button
              class="event__button"
              @click="addEvent()"
              v-b-modal.modal__event
            >
              Создать
            </button>
          </div>
        </b-col>

        <b-col cols="12" md="4" v-for="(event, index) in events" :key="index">
          <EventCard :event="event" buttonText="Редактировать" :index="index" />
        </b-col>
      </b-row>
    </b-container>

    <EventModalAdmin />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import EventCard from "@/components/events/EventCard.vue";
import EventModalAdmin from "@/components/events/EventModalAdmin.vue";

export default {
  name: "EventEditor",
  components: {
    EventCard,
    EventModalAdmin,
  },
  methods: {
    ...mapActions(["getEvents", "getUser"]),
    addEvent() {
      this.$store.state.events.events.push({
        title: "",
        description: "",
        speaker: "",
        about_speaker: "",
        location: "",
        date_time: "",
        count_seats: "",
        prices: [],
        bk_prices: [],
      });

      this.$store.commit(
        "SET_ACTIVE_EVENT",
        this.$store.state.events.events.length - 1
      );
      this.$store.commit("SET_EDITING_EVENT");

      this.$store.commit("SET_TYPE_EDITING", "add");
    },
  },
  computed: {
    events() {
      return this.$store.state.events.events;
    },
  },
  async mounted() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ name: "Login" });
    } else {
      try {
        this.$store.commit("SET_LOADING", true);

        await this.getUser();
        await this.getEvents();

        this.$store.commit("SET_LOADING", false);
      } catch (e) {
        this.$store.commit("SET_LOADING", false);
        console.error(e);
      }
    }
  },
};
</script>

<style lang="scss">
.title-event {
  font-size: 20px;
  font-weight: 600;

  margin: 0;
}
.title-modal {
  font-size: 22px;
  padding-bottom: 30px;
}
.title-event_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: $accent;
  height: 78px;

  padding: 10px 10px 10px 10px;
  margin-bottom: 15px;
}
.description-event-modal {
  font-size: 18px;
  padding-bottom: 15px;
  font-weight: 400;
}
</style>
