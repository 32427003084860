<template>
  <div v-if="event">
    <div class="event" :class="eventOld ? 'old' : ''">
      <div class="event-img__container">
        <img
          v-if="event.img"
          class="event-img__img"
          :src="$store.state.storageUrl + '/app/events/' + event.img"
          alt=""
        />
        <img
          v-else
          class="event-img__img"
          :src="$store.state.storageUrl + '/app/defaults/darkLogo.jpg'"
          alt=""
        />
      </div>

      <h2 v-if="event.speaker" class="event_speaker text-center">
        {{ event.speaker }}
      </h2>

      <div class="title-event_wrapper">
        <h3 v-if="event.title && event.title.length < 47" class="title-event">
          {{ event.title }}
        </h3>
        <h3 v-else class="title-event">
          {{ event.title.substring(0, 47) + ".." }}
        </h3>
      </div>

      <div class="event_datetime_container col d-flex justify-content-between">
        <p v-if="event.date_time" class="event_date-time">
          {{ formatDateTime(event.date_time, "time") }}
        </p>
        <p v-if="event.date_time" class="event_date-time time">
          {{ formatDateTime(event.date_time, "date") }}
        </p>
      </div>

      <!-- Registrations for admin -->
      <button
        v-if="buttonText && admin"
        class="event__button"
        @click="toEvent(event.id)"
      >
        {{ buttonText }}
      </button>

      <button
        v-else
        :disabled="eventOld"
        class="event__button"
        @click="setActiveEvent(index)"
        v-b-modal.modal__event
      >
        {{ eventOld ? "Прошло" : buttonText }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "EventCard",
  props: {
    event: Object,
    buttonText: String,

    index: Number,
    modal: String,
    admin: Boolean,
  },
  computed: {
    eventOld() {
      return (
        moment(this.event.date_time).format("YYYY-MM-DD") <
        moment(new Date()).format("YYYY-MM-DD")
      );
    },
  },
  methods: {
    setActiveEvent(index) {
      this.$store.commit("SET_ACTIVE_EVENT", index);
      this.$store.commit("SET_EDITING_EVENT");
    },
    // DateTime format
    formatDateTime(dateTime, type) {
      moment.locale("ru");
      var result;

      switch (type) {
        case "date":
          result = moment(String(dateTime)).format("LT");
          break;

        case "time":
          result = moment(String(dateTime)).format("DD MMMM");
          break;
      }

      return result;
    },
    toEvent(id) {
      this.$router.push({ name: "Registrations", query: { event: id } });
    },
  },
};
</script>

<style lang="scss">
/* main event */
.event {
  background-color: $black;
  color: $white;
  margin-bottom: 30px;
}
.event.old {
  background-color: $black;
  color: $white;
  margin-bottom: 30px;
  filter: saturate(0%);
}

.title-event {
  font-size: 20px;
  font-weight: 600;

  margin: 0;
}
.title-event_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: $accent;
  height: 78px;

  padding: 10px 10px 10px 10px;
  margin-bottom: 15px;
}

.event_speaker {
  font-size: 18px;
  margin: 0;
  padding-bottom: 15px;

  font-weight: 300;
  letter-spacing: 2px;
}

.event_datetime_container {
  padding-bottom: 60px;
}
.event_date-time {
  margin: 0;
  font-size: 20px;
  font-weight: 300;
  color: $white;
}
.event_date-time.time {
  font-weight: 700;
  font-size: 28px;
  color: $accent;
}

.exit_button {
  position: absolute;
  top: 15px;
  right: 15px;

  background-color: unset;
  border: none;
}

/* button */
.event__button {
  outline: none;
  background-color: rgba($black, 0);

  border-radius: 0px;
  border-color: $accent;
  border-width: 1px;
  border-style: solid;

  padding: 10px 30px 10px 30px;
  width: 100%;

  color: $white;
  font-weight: 300;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;

  transition: 100ms linear;
}
.event__button:hover,
.btn-secondary:hover {
  outline: none;

  border-radius: 0px;
  border-color: $accent;
  border-width: 1px;
  border-style: solid;

  background-color: $accent;

  transition: 100ms linear;
  box-shadow: 0px 0px 14px 0px rgba($accent, 0.7);
}
.event__button:focus,
.event__button:active {
  outline: none;

  border-radius: 0px;
  border-color: $accent;
  border-width: 1px;
  border-style: solid;

  transition: 100ms linear;
  box-shadow: 0px 0px 14px 0px rgba($accent, 0.7);
}
.event__button:active {
  outline: none;
  background-color: rgba($black, 0);

  border-radius: 0px;
  border-color: $accent;
  border-width: 1px;
  border-style: solid;

  transition: 100ms linear;
  box-shadow: 0px 0px 14px 0px rgba($accent, 0.7);
}
.event__button.old {
  cursor: default;
}
</style>
